import React from "react"

import { withCheckbox } from "./withCheckbox"
import { Label, Wrapper, SquareInner, SquareOuter } from "./CheckboxStyles"

interface Props {
  capitalize: boolean
  centered: boolean
  checked: boolean
  children: any
  externalState: boolean
  id: string
  isChecked: boolean
  large: boolean
  name: string
  handleClick: any
  rounded: boolean
  disable: boolean
}

export const Checkbox = withCheckbox(
  ({
    capitalize,
    centered = false,
    checked = true,
    children = null,
    externalState,
    handleClick,
    id = null,
    isChecked = false,
    large = false,
    name = null,
    rounded = null,
    disable = false,
  }: Props) => (
    <Wrapper id={id} name={name} className={`group`} onClick={disable ? "" : handleClick} centered={centered}>
      <SquareOuter disabled={disable ? true : false} checked={externalState ? checked : false} rounded={rounded}>
        <SquareInner disabled={disable ? true : false} checked={externalState ? checked : false} rounded={rounded} />
      </SquareOuter>

      {children && (
        <Label disabled={disable ? true : false} capitalize={capitalize} centered={centered} large={large}>
          {children}
        </Label>
      )}
    </Wrapper>
  )
)
