import React from "react"

import { useCustomerDetails } from "../../../../hooks/useCustomer"
import { useLanguage } from "../../../../hooks/useLanguage"

export const withCustomerPreferencesForm = Component => ({ name = `CustomerPreferencesForm` }) => {
  const { customer, setCustomer, savedCustomer, updateCustomer, loading, saving, errors } = useCustomerDetails()
  const locales = { ...useLanguage(`account`), ...useLanguage(`form`) }

  const handleSubmit = async event => {
    event.preventDefault()
    await updateCustomer(customer)
  }

  const handleChange = ({ name, target, type }) => {
    setCustomer(prevState => ({
      ...prevState,
      [target?.name || name]: type === "checkbox" ? !customer[name] : target?.value,
    }))
  }

  const fields = [
    {
      name: "acceptsMarketing",
      label: "Newsletter",
    },
  ]

  const changed = fields.filter(field => customer?.[field?.name] !== savedCustomer?.[field?.name])

  Component.displayName = name
  return (
    <Component
      changed={changed?.length > 0}
      customer={customer}
      errors={errors}
      fields={fields}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading || saving}
      locales={locales}
    />
  )
}
