import React, { useState } from "react"

export const withCheckbox = Component => ({
  componentName = `Checkbox`,
  capitalize,
  centered,
  checked,
  children,
  externalState,
  id,
  large,
  name,
  onChange,
  rounded,
  disable,
}) => {
  const [isChecked, setChecked] = useState(checked)

  const handleClick = () => {
    const state = !isChecked

    if (!externalState) {
      setChecked(state)
    }

    if (onChange) {
      onChange({ checked: state, id, name, type: `checkbox` })
    }
  }

  Component.displayName = componentName
  return (
    <Component
      capitalize={capitalize}
      centered={centered}
      checked={checked}
      externalState={externalState}
      handleClick={handleClick}
      id={id}
      isChecked={isChecked}
      large={large}
      name={name}
      rounded={rounded}
      disable={disable}
    >
      {children}
    </Component>
  )
}
